import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import 'react-h5-audio-player/lib/styles.css'
import { Wrapper } from 'styles/_mixins'

import Lines from 'components/Lines/Lines'
import DiscographyCard from 'components/Discography/DiscographyCard'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 15rem 0;
`

const Discography = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          discography {
            id
            slug
            title
          }
        }
      }
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/songs/" } }) {
        edges {
          node {
            frontmatter {
              songs {
                id
                title
                song {
                  id
                  name
                  publicURL
                }
                images {
                  src {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug } = datas.markdownRemark.frontmatter.discography

  return (
    <Container id={slug}>
      <Lines widht='0.5rem' height='10rem' top='-0.5rem' left='50%' />
      <Wrapper>
        <DiscographyCard datas={datas} />
      </Wrapper>
      <Lines widht='0.5rem' height='10rem' bottom='-0.5rem' left='50%' />
    </Container>
  )
}

export default Discography
