import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import isEmail from 'validator/lib/isEmail'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { maxScreen } from 'styles/_breakpoints'
import {
  Title,
  Message,
  Form,
  FormGroup,
  FormLabel,
  FormInput,
  FormMessage,
  FormButton,
  CheckBox,
  CheckBoxInput,
  CheckBoxLabel,
  CheckBoxSpan,
} from 'components/Contact/_contactform'

const Container = styled.section`
  position: relative;
  z-index: 2;
  width: 100%;
  height: fit-content;

  @media ${maxScreen.tablet} {
    width: 100%;
  }
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        contact: {
          id: number
          title: string
        }
      }
    }
  }
}

type ContactProps = {
  name: string
  email: string
  message: string
  checkbox: boolean
  errors: any
}

const ContactForm = ({ datas }: Datas) => {
  const { title } = datas.markdownRemark.frontmatter.contact
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<ContactProps>({
    mode: 'onChange',
  })
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '',
    checkbox: false,
  })

  const { name, email, message, checkbox } = values

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setValues({ ...values, [e.target.name]: e.target.value, checkbox: true })
  }

  const onSubmit = handleSubmit((data, e: any) => {
    e.preventDefault()

    const form = e.target

    axios({
      method: 'post',
      url: 'https://formspree.io/f/mwkwvdvr',
      data: new FormData(form),
    })
      .then((response) => {
        if (checkbox) e.target.reset()

        toast.dark(`Thanks for reaching out. I'll get back to you soon.`)
        setValues({
          ...values,
          name: '',
          email: '',
          message: '',
          checkbox: false,
        })
      })
      .catch((error) => {
        console.log(error)
        toast.dark('Oops, something went wrong. The form could not be submitted.')
        setValues({ ...values, checkbox: false })
      })
  })

  return (
    <Container>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Title>{title}</Title>

      <Form name='contact' onSubmit={onSubmit}>
        <FormGroup>
          <FormLabel htmlFor='name' className='form-label'>
            Name
          </FormLabel>
          <FormInput
            {...register('name', {
              required: true,
              minLength: 3,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i,
            })}
            id='name'
            type='text'
            name='name'
            placeholder='Name'
            value={name}
            onChange={handleChange}
          />
          {errors.name && <Message>Please enter a valid name.</Message>}
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor='email' className='form-label'>
            Email
          </FormLabel>
          <FormInput
            {...register('email', {
              required: true,
              validate: (input) => isEmail(input),
              minLength: 6,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
            })}
            id='email'
            type='email'
            name='email'
            placeholder='Email'
            value={email}
            onChange={handleChange}
          />
          {errors.email && <Message>Please enter a valid email.</Message>}
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor='message' className='form-label'>
            Message
          </FormLabel>
          <FormMessage
            {...register('message', {
              required: true,
              maxLength: 300,
            })}
            id='message'
            name='message'
            placeholder='Message'
            value={message}
            onChange={handleChange}
          />
          {errors.message && <Message>Please enter a message.</Message>}
        </FormGroup>

        <CheckBox>
          <CheckBoxLabel htmlFor='checkbox'>
            <CheckBoxInput
              {...register('checkbox', { required: true })}
              type='checkbox'
              name='checkbox'
              id='checkbox'
            />
            I'm a human.
            <CheckBoxSpan onChange={handleChange}></CheckBoxSpan>
          </CheckBoxLabel>

          {errors.checkbox && <Message>Please check the checkbox.</Message>}
        </CheckBox>

        <FormButton type='submit' disabled={formState.isSubmitting}>
          Send
        </FormButton>
      </Form>
    </Container>
  )
}

export default ContactForm
