import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { deviceScreen, maxScreen } from 'styles/_breakpoints'

export const Container = styled.section`
  width: 100%;
  height: fit-content;
  margin: 2rem 0;
`

export const Title = styled.h2`
  margin-bottom: 5rem;
`

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  grid-auto-rows: auto;
  gap: 5rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  width: 100%;
  height: 29rem;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 10px 5px ${({ theme: { colors } }) => colors.lightgray};

  @media ${maxScreen.smland} {
    flex-direction: column;
    height: 57rem;
  }

  @media ${deviceScreen.smland} {
    flex-direction: column;
    height: 65rem;
  }

  @media ${deviceScreen.tablet} {
    height: 32rem;
  }

  @media ${deviceScreen.desktop} {
    flex-direction: column;
    height: 60rem;
  }

  @media ${deviceScreen.land} {
    height: 27rem;
  }
`

export const CardImageLink = styled(Link)`
  width: 100%;
  height: 100%;
`

export const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
`

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;

  @media ${maxScreen.smart} {
    padding: 2rem;
  }

  @media ${deviceScreen.land} {
    padding: 0 3rem;
  }
`

export const CardDate = styled.p`
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

export const CardTitle = styled.h3`
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
`

export const CardDescription = styled.p`
  margin-bottom: 3.5rem;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: ${({ theme: { colors } }) => colors.darkgray};
`

export const CardLink = styled(Link)`
  font-size: 2.25rem;
  color: ${({ theme: { colors } }) => colors.black};
`

export const CardSpan = styled.span`
  color: ${({ theme: { colors } }) => colors.pink};
`
