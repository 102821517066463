import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Wrapper } from 'styles/_mixins'

import Lines from 'components/Lines/Lines'
import ToursCard from 'components/Tours/ToursCard'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 15rem 0;
`

const Title = styled.h2`
  margin-bottom: 5rem;
`

const Tours = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          tour {
            id
            slug
            title
          }
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/tours/" } }
        sort: { fields: frontmatter___cards___id, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              cards {
                id
                title
                date
                location
                icon {
                  alt
                  src {
                    publicURL
                  }
                }
                image {
                  alt
                  src {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        quality: 100
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.tour

  return (
    <Container id={slug}>
      <Lines widht='0.5rem' height='10rem' top='-0.5rem' left='50%' />
      <Wrapper>
        <Title>{title}</Title>
        <ToursCard datas={datas} />
      </Wrapper>
      <Lines widht='0.5rem' height='10rem' bottom='-0.5rem' left='50%' />
    </Container>
  )
}

export default Tours
