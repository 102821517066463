import React from 'react'

import {
  CardGrid,
  Card,
  CardImageLink,
  CardImage,
  CardBody,
  CardTitle,
  CardDate,
  CardDescription,
  CardLink,
  CardSpan,
} from 'components/News/_news'

interface Datas {
  node: {
    frontmatter: {
      posts: Post
    }
  }
}

interface Post {
  id: number
  slug: string
  title: string
  date: string
  snippet: string
  description: string
  images: {
    src: any
    alt: string
  }
}

const NewsCards = ({ datas }: any) => {
  const blogDatas = datas.allMarkdownRemark.edges.map(({ node }: Datas) => (
    <Card key={node.frontmatter.posts.id}>
      <CardImageLink
        to={`/post/${node.frontmatter.posts.slug}`}
        aria-label={node.frontmatter.posts.title}
      >
        <CardImage
          image={node.frontmatter.posts.images.src.childImageSharp.gatsbyImageData}
          alt={node.frontmatter.posts.images.alt}
        />
      </CardImageLink>
      <CardBody>
        <CardDate>{node.frontmatter.posts.date}</CardDate>
        <CardTitle>{node.frontmatter.posts.title}</CardTitle>
        <CardDescription>{node.frontmatter.posts.snippet}...</CardDescription>
        <CardLink to={`/post/${node.frontmatter.posts.slug}`}>
          Read Post <CardSpan>{`>`}</CardSpan>
        </CardLink>
      </CardBody>
    </Card>
  ))

  return <CardGrid>{blogDatas.slice(0, 4)}</CardGrid>
}

export default NewsCards
