import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Wrapper } from 'styles/_mixins'

import Lines from 'components/Lines/Lines'
import AboutCard from 'components/About/AboutCard'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 20rem 0 15rem;
`

const About = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          about {
            id
            slug
            title
            description
            images {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 85, formats: [AUTO, WEBP])
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug } = datas.markdownRemark.frontmatter.about

  return (
    <Container id={slug}>
      <Lines widht='0.5rem' height='15rem' top='-0.5rem' left='50%' />
      <Wrapper>
        <AboutCard datas={datas} />
      </Wrapper>
      <Lines widht='0.5rem' height='10rem' bottom='-0.5rem' left='50%' />
    </Container>
  )
}

export default About
