import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  z-index: -1;
`

const Line = styled.div`
  width: 0.35rem;
  border-radius: 10rem;
  background: ${({ theme: { colors } }) => colors.pink};
`

const Lines = ({ width, height, top, right, bottom, left, classname }: any) => {
  return (
    <Container style={{ top: top, right: right, bottom: bottom, left: left }}>
      <Line className={classname} style={{ height: height, width: width }} />
    </Container>
  )
}

export default Lines
