import styled from 'styled-components'

import { Props } from 'types/types'

import { maxScreen } from 'styles/_breakpoints'

export const Title = styled.h2`
  margin-bottom: 5rem;
`

export const Form = styled.form`
  width: 100%;
`

export const FormBox = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-between;

  @media ${maxScreen.tablet} {
    width: 100%;
    flex-direction: column;
  }

  select,
  input {
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.opblack};
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

export const FormLabel = styled.label`
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  font-family: ${({ theme: { fonts } }) => fonts.bebasneue};
  color: ${({ theme: { colors } }) => colors.black};
`

export const FormInput = styled.input<Props>`
  padding: 1rem;
  font-size: 2rem;
  font-family: ${({ theme: { fonts } }) => fonts.bebasneue};
  border: none;
  background: ${({ theme: { colors } }) => colors.oppink};
  color: ${({ theme: { colors } }) => colors.darkgray};

  &:focus {
    outline: none;
  }
`

export const FormMessage = styled.textarea<Props>`
  height: 15rem;
  resize: vertical;
  padding: 1rem;
  font-size: 2rem;
  font-family: ${({ theme: { fonts } }) => fonts.bebasneue};
  border-radius: 0.25rem;
  border: none;
  background: ${({ theme: { colors } }) => colors.oppink};
  color: ${({ theme: { colors } }) => colors.darkgray};

  &:focus {
    outline: none;
  }
`

export const FormButton = styled.button`
  padding: 0.85rem 2.25rem 0.65rem;
  text-transform: uppercase;
  font-size: clamp(2rem, 2vw, 2.25rem);
  font-family: ${({ theme: { fonts } }) => fonts.bebasneue};
  border-radius: 0.25rem;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.pink};
  background: ${({ theme: { colors } }) => colors.pink};
  color: ${({ theme: { colors } }) => colors.black};
  cursor: pointer;

  @media ${maxScreen.desktop} {
    padding: 0.75rem 2rem;
  }
`

// ERROR MESSAGE

export const Message = styled.p`
  margin-top: 0.75rem;
  color: ${({ theme: { colors } }) => colors.red};
`

// CHECKBOX

export const CheckBox = styled.section`
  position: relative;
  width: fit-content;
  margin-bottom: 6rem;
  font-size: 1.5rem;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

export const CheckBoxSpan = styled.span`
  display: block;
`

export const CheckBoxInput = styled.input<Props>`
  width: fit-content;
  height: 25px;
  width: 25px;
  margin-right: 1rem;
  appearance: none;
  background: ${({ theme: { colors } }) => colors.oppink};
  border: none;
  border-radius: 0.25rem;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;

  &:checked {
    background: ${({ theme: { colors } }) => colors.oppink};
    border: none;
  }

  &:active {
    border: none;
  }
`

export const CheckBoxLabel = styled.label`
  display: flex;
  margin-bottom: 0;
  font-size: 1.6rem;
  cursor: pointer;

  & > ${CheckBoxInput}:checked + ${CheckBoxSpan}::before {
    content: '✔';
    color: ${({ theme: { colors } }) => colors.green};
    position: absolute;
    left: 0.7rem;
    top: 0;
    font-size: 1.6rem;
  }
`
