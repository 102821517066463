import React, { useState } from 'react'
import styled from 'styled-components'
import AudioPlayer from 'react-h5-audio-player'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { deviceScreen, maxScreen, screen } from 'styles/_breakpoints'

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(42%, 1fr));
  grid-auto-rows: auto;
  gap: 7.5rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 100%;
    gap: 0;
  }
`

const Title = styled.h2`
  margin-bottom: 5rem;
`

const Card = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
  padding-right: 1rem;

  .rhap_container {
    background: none;
    box-shadow: none;
    padding: 0;

    & svg {
      color: ${({ theme: { colors } }) => colors.pink};
    }
  }

  .rhap_main {
    position: relative;
    flex-direction: row;
  }

  .rhap_progress-section {
    margin-left: 6rem;

    .rhap_progress-filled {
      background: ${({ theme: { colors } }) => colors.pink};
    }
  }

  .rhap_controls-section {
    margin-top: 0 !important;
  }

  .rhap_main-controls-button {
    position: absolute;
    left: -0.5rem;
    border-radius: 50%;
    background: ${({ theme: { colors } }) => colors.white};
    box-shadow: 1px 1px 5px 2px ${({ theme: { colors } }) => colors.lightgray};
  }
`

const Box = styled.div`
  width: 100%;
  height: 65rem;

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    display: none;
  }
`

const CardBox = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 75rem;
  overflow-y: scroll;
  scrollbar-width: none;
  cursor: n-resize;

  &:-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const CardTitle = styled.h3`
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
`

const CardText = styled.p`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;
  font-size: 2rem;
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-family: ${({ theme: { fonts } }) => fonts.bebasneue};

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.tablet})`} {
    display: none;
  }
`

const CardImageBox = styled.div`
  position: absolute;
  top: 20rem;
  right: 0;
  width: 100%;
  height: 75rem;

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.tablet})`} {
    margin-top: 6rem;
  }

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    position: relative;
    top: 0;
    left: 0;
  }

  @media ${maxScreen.smart} {
    height: 30rem;
  }

  @media ${deviceScreen.smland} {
    height: 45rem;
  }

  @media ${deviceScreen.tablet} {
    height: 60rem;
  }
`

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 0, 52% 50%, 100% 100%);
  transition: all 0.3s ease-in-out;

  img {
    object-fit: cover !important;
  }

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    position: relative;
    top: 0;
    left: 0;
    clip-path: none;
  }
`

const DiscographyCard = ({ datas }: any) => {
  const [state, setState] = useState({
    currentIndex: 0,
  })
  const { currentIndex } = state

  const { title } = datas.markdownRemark.frontmatter.discography

  const songDatas = datas.allMarkdownRemark.edges[0].node.frontmatter.songs

  const songData = songDatas.map(({ id, title, song }: any) => (
    <Card key={id}>
      <CardTitle>{title}</CardTitle>

      <AudioPlayer
        autoPlayAfterSrcChange={true}
        showSkipControls={false}
        showJumpControls={false}
        showFilledProgress={true}
        src={song.publicURL}
        customAdditionalControls={[]}
        onPlay={(e) => setState({ currentIndex: id })}
      />
    </Card>
  ))

  return (
    <>
      <Title>{title}</Title>
      <GridBox>
        <CardBox>{songData}</CardBox>

        <Box />

        <CardImageBox>
          <CardImage
            image={songDatas[currentIndex].images.src.childImageSharp.gatsbyImageData}
            alt={songDatas[currentIndex].images.alt}
          />
        </CardImageBox>
        <CardText>Scroll down</CardText>
      </GridBox>
    </>
  )
}

export default DiscographyCard
