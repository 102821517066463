import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Wrapper } from 'styles/_mixins'

import HeroBG from 'components/Hero/HeroBG'
import HeroContent from 'components/Hero/HeroContent'

const Container = styled.section`
  width: 100%;
  height: 100vh;

  & ${Wrapper} {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }
`

const Hero = () => {
  const datas = useStaticQuery(
    graphql`
      query {
        markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
          frontmatter {
            hero {
              id
              slug
              title
              description
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                alt
              }
            }
          }
        }
      }
    `,
  )

  const { slug } = datas.markdownRemark.frontmatter.hero

  return (
    <Container id={slug}>
      <HeroBG datas={datas} />

      <Wrapper>
        <HeroContent datas={datas} />
      </Wrapper>
    </Container>
  )
}

export default Hero
