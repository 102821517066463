import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { Wrapper } from 'styles/_mixins'
import { deviceScreen, maxScreen, screen } from 'styles/_breakpoints'

import Lines from 'components/Lines/Lines'
import ContactForm from 'components/Contact/ContactForm'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 15rem 0 20rem;
`

const GridBox = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(42%, 1fr));
  grid-auto-rows: auto;
  gap: 7.5rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 100%;
    gap: 0;
  }
`

const Box = styled.div`
  width: 100%;
  height: 75rem;

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    display: none;
  }
`

const ImageBox = styled.div`
  position: absolute;
  top: 10rem;
  left: 0;
  width: 100%;
  height: 90rem;

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.tablet})`} {
    margin-bottom: 6rem;
  }

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    position: relative;
    top: 0;
    left: 0;
  }

  @media ${maxScreen.smart} {
    height: 30rem;
  }

  @media ${deviceScreen.smland} {
    height: 45rem;
  }

  @media ${deviceScreen.tablet} {
    height: 60rem;
  }
`

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 0% 100%, 48% 50%);
  transition: all 0.5s ease-in-out;

  img {
    object-fit: cover !important;
  }

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    clip-path: none;
  }
`

const Contact = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          contact {
            id
            slug
            title
            images {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug, images } = datas.markdownRemark.frontmatter.contact

  return (
    <Container id={slug}>
      <Lines widht='0.5rem' height='10rem' top='-0.5rem' left='50%' />
      <Wrapper height='true'>
        <GridBox>
          <ImageBox>
            <Image image={images.src.childImageSharp.gatsbyImageData} alt={images.alt} />
          </ImageBox>

          <Box />
          <ContactForm datas={datas} />
        </GridBox>
      </Wrapper>
      <Lines widht='0.5rem' height='15rem' bottom='-0.5rem' left='50%' />
    </Container>
  )
}

export default Contact
