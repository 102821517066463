import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Wrapper } from 'styles/_mixins'

import Lines from 'components/Lines/Lines'
import NewsCards from 'components/News/NewsCards'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 15rem 0;
`

const Title = styled.h2`
  margin-bottom: 5rem;
`

const News = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          blog {
            id
            slug
            title
          }
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/news/" } }
        sort: { fields: frontmatter___posts___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              posts {
                id
                slug
                title
                date
                snippet
                description
                images {
                  src {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.blog

  return (
    <Container id={slug}>
      <Lines widht='0.5rem' height='10rem' top='-0.5rem' left='50%' />
      <Wrapper>
        <Title>{title}</Title>
        <NewsCards datas={datas} />
      </Wrapper>
      <Lines widht='0.5rem' height='10rem' bottom='-0.5rem' left='50%' />
    </Container>
  )
}

export default News
