import React from 'react'
import styled from 'styled-components'

import { AnimationFade } from 'libs/animation'

import { maxScreen, deviceScreen } from 'styles/_breakpoints'

import Down from 'components/Down/Down'

const Content = styled.div`
  position: relative;
  z-index: 3;
  width: clamp(28rem, 35vw, 40rem);
  height: fit-content;

  @media ${deviceScreen.smland} {
    margin-top: 6rem;
  }

  @media ${deviceScreen.desktop} {
    margin-top: 1rem;
  }
`

const Description = styled.p`
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 2.5rem;
  font-size: clamp(1.4rem, 1.8vw, 2rem);
  color: ${({ theme: { colors } }) => colors.white};

  @media ${maxScreen.smart} {
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }

  @media ${deviceScreen.smland} {
    line-height: 1.8rem;
    margin-bottom: 0.5rem;
  }

  @media ${deviceScreen.tablet} {
    line-height: 2rem;
  }

  @media ${deviceScreen.desktop} {
    line-height: 2.5rem;
  }
`

const Title = styled.h1`
  margin-bottom: 3rem;

  @media ${maxScreen.smart} {
    margin-bottom: 1.5rem;
  }

  @media ${deviceScreen.smland} {
    margin-bottom: 0;
  }

  @media ${deviceScreen.tablet} {
    margin-bottom: 2rem;
  }

  @media ${deviceScreen.desktop} {
    margin-bottom: 0;
  }
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        hero: {
          title: string
          description: string
        }
      }
    }
  }
}

const HeroContent = ({ datas }: Datas) => {
  const { title, description } = datas.markdownRemark.frontmatter.hero

  return (
    <Content>
      <AnimationFade rootMargin='0px 0px' custom={0} duration={1}>
        <Description>{description}</Description>
        <Title>{title}</Title>
        <Down />
      </AnimationFade>
    </Content>
  )
}

export default HeroContent
