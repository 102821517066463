import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Container = styled.div`
  width: fit-content;
  height: fit-content;
`

const Image = styled(motion.img)`
  width: 2.5rem;
  height: 2.5rem;
  filter: ${({ theme: { filters } }) => filters.pink};
`

const Down = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          down {
            id
            title
            images {
              src {
                publicURL
              }
              alt
            }
            to
          }
        }
      }
    }
  `)

  const { title, images, to } = datas.markdownRemark.frontmatter.down

  const variants = {
    visible: {
      y: [0, 5, 15, 25, 15, 5, 0],
      transition: { repeat: Infinity, duration: 1 },
    },
  }

  return (
    <Container>
      <AnchorLink to={to} stripHash>
        <Image
          variants={variants}
          animate='visible'
          src={images.src.publicURL}
          alt={images.alt}
          title={title}
        />
      </AnchorLink>
    </Container>
  )
}

export default Down
