import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { deviceScreen, maxScreen } from 'styles/_breakpoints'

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28%, 1fr));
  grid-auto-rows: auto;
  gap: 3rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
`

const Card = styled.div`
  width: 100%;
  height: fit-content;
  box-shadow: 0px 2px 10px 5px ${({ theme: { colors } }) => colors.lightgray};
`

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 50rem;
  transition: all 0.5s ease-in-out;

  @media ${maxScreen.smland} {
    height: 35rem;
  }

  @media ${deviceScreen.tablet} {
    height: 55rem;
  }

  @media ${deviceScreen.desktop} {
    height: 35rem;
  }
`

const CardBody = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CardTitle = styled.h3`
  margin-bottom: 1rem;
  font-size: 3.5rem;
`

const CardBox = styled.div`
  position: absolute;
  top: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 5rem;
  background: ${({ theme: { colors } }) => colors.pink};
`

const CardDate = styled.p`
  margin-bottom: 0;
  font-size: 2.25rem;
  font-family: ${({ theme: { fonts } }) => fonts.bebasneue};
`

const CardFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CardIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  filter: ${({ theme: { filters } }) => filters.pink};
`

const CardText = styled.p`
  margin-bottom: 0;
  padding-left: 0.5rem;
  font-size: 1.7rem;
`

interface Datas {
  datas: {
    allMarkdownRemark: {
      edges: Edges[]
    }
  }
}

interface Edges {
  node: {
    frontmatter: {
      cards: Cards[]
    }
  }
}

interface Cards {
  id: number
  title: string
  date: string
  location: string
  icon: {
    alt: string
    src: {
      publicURL: string
    }
  }
  image: {
    alt: string
    src: any
  }
}

const EventsCard = ({ datas }: Datas) => {
  const imageDatas = datas.allMarkdownRemark.edges

  const imageData = imageDatas.map(({ node }: Edges) =>
    node.frontmatter.cards.map(({ id, title, date, icon, location, image }) => (
      <Card key={id}>
        <CardImage
          key={id}
          image={image.src.childImageSharp.gatsbyImageData}
          alt={image.alt}
        />
        <CardBody>
          <CardBox>
            <CardDate>{date}</CardDate>
          </CardBox>
          <CardTitle>{title}</CardTitle>
          <CardFlex>
            <CardIcon src={icon.src.publicURL} alt={icon.alt} />
            <CardText>{location}</CardText>
          </CardFlex>
        </CardBody>
      </Card>
    )),
  )

  return <GridBox>{imageData}</GridBox>
}

export default EventsCard
