import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { deviceScreen, maxScreen, screen } from 'styles/_breakpoints'

import Social from 'components/Social/Social'

const GridBox = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(42%, 1fr));
  grid-auto-rows: auto;
  gap: 7.5rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 100%;
    gap: 0;
  }
`

const Content = styled.div`
  width: 100%;
  height: fit-content;
  color: ${({ theme: { colors } }) => colors.black};
`

const Title = styled.h2`
  margin-bottom: 5rem;
`

const Description = styled.div`
  margin-bottom: 6rem;

  p {
    margin-bottom: 2.5rem;
    font-size: 1.6rem;
    line-height: 3.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Box = styled.div`
  width: 100%;
  height: 85rem;

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    display: none;
  }
`

const ImageBox = styled.div`
  position: absolute;
  top: 15rem;
  left: 0;
  width: 100%;
  height: 90rem;

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.tablet})`} {
    margin-bottom: 6rem;
  }

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    position: relative;
    top: 0;
    left: 0;
  }

  @media ${maxScreen.smart} {
    height: 30rem;
  }

  @media ${deviceScreen.smland} {
    height: 45rem;
  }

  @media ${deviceScreen.tablet} {
    height: 60rem;
  }
`

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 0% 100%, 48% 50%);
  transition: all 0.5s ease-in-out;

  img {
    object-fit: cover !important;
  }

  @media ${`(min-width: ${screen.phone}) and (max-width: ${screen.laptop})`} {
    clip-path: none;
  }
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        about: {
          title: string
          description: string
          images: {
            src: any
            alt: string
          }
        }
      }
    }
  }
}

const AboutCard = ({ datas }: Datas) => {
  const { title, description, images } = datas.markdownRemark.frontmatter.about

  return (
    <GridBox>
      <ImageBox>
        <Image image={images.src.childImageSharp.gatsbyImageData} alt={images.alt} />
      </ImageBox>

      <Box />
      <Content>
        <Title>{title}</Title>
        <Description>
          <ReactMarkdown>{description}</ReactMarkdown>
        </Description>
        <Social />
      </Content>
    </GridBox>
  )
}

export default AboutCard
